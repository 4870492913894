.switch-toggle {
    float: center;
    background: rgb(97, 97, 97);
    border-radius: 20px;
    width: 90%;
 }
 .switch-toggle input {
   position: absolute;
   opacity: 0;
 }
 .switch-toggle input + label {
   
   padding: 1px;
   float:center;
   color: rgb(255, 255, 255);
   cursor: pointer;
   border-radius: 10px;
   width: 33%;
   transition: background-color .2s;
 }
 .switch-toggle input:checked + label {
   background: rgb(6, 214, 160);
 }