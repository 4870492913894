@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Quicksand', sans-serif !important;
}

.parameter-grid {
  text-align: center;
  padding: 10px;  /* Añade un poco de espacio interno para evitar que los textos se peguen a los bordes */
}

.value-badge {
  display: inline-block;
  min-width: 40px;
  padding: 5px;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
}

/* Ajusta la tarjeta principal para evitar desbordamientos */
.card-container {
  max-width: 400px; /* Puedes ajustar este valor dependiendo del diseño */
  overflow: hidden;
  margin: 0 auto; /* Centra la tarjeta en su contenedor */
  border-radius: 25px;
}

/* Asegura que el grid principal no desborde */
.MuiGrid-root {
  box-sizing: border-box;
  max-width: 100%;
}

.pac-container {
  z-index: 1500 !important; /* Asegúrate que el valor sea mayor al del <Dialog> */
}

