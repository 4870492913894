.table-container {
	display: grid;
	/* grid-template-columns: repeat(7, 1fr); */
	/* Asignar un ancho fijo a la primera columna y el resto distribuir igual */
	grid-template-columns: 0.3fr 1.5fr repeat(5, 1fr);
	gap: 10px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
  }

.table-container-cell {
	display: grid;
	/* grid-template-columns: repeat(7, 1fr); */
	/* Asignar un ancho fijo a la primera columna y el resto distribuir igual */
	grid-template-columns: 0.3fr 1.5fr repeat(5, 1fr);
	gap: 10px;
	padding: 10px;
	/* border: 1px solid #ddd; */
	border-radius: 5px;
  }
  
  .table-row {
	display: contents;
  }
  
  .table-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	/* border-bottom: 1px solid #ddd; */
	text-align: center;
  }
  .table-cell-rows {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 10px;
	/* border-bottom: 1px solid #ddd; */
	text-align: flex-start;
  }
  
  .table-cell:first-child {
	justify-content: flex-start;
  }
  .table-cell-rows:first-child {
	justify-content: flex-start;
  }
  
  .table-cell-rows input,
  .table-cell-rows select {
	width: 100%;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
  }
  
  .table-cell-rows .badge {
	background-color: #3B3C43;
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
  }

  .table-cell-rows .icon {
	background-color: #00CED1;
	color: white;
	padding: 3px 6px;
	border-radius: 8px;
  }
  
  .table-cell .delete-btn {
	background-color: #ff4d4f;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .table-cell .delete-btn:hover {
	background-color: #d32f2f;
  }

  .table-cell-rows .delete-btn {
	background-color: #ff4d4f;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .table-cell-rows .delete-btn:hover {
	background-color: #d32f2f;
  }
  
  @media (max-width: 768px) {
	.table-container {
	  grid-template-columns: 1fr;
	}
  
	.table-cell {
	  justify-content: flex-start;
	  text-align: left;
	  border-bottom: none;
	  border-right: 1px solid #ddd;
	}
  
	.table-cell:last-child {
	  border-right: none;
	}
  }
  